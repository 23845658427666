import "../styles/mui.scss";

import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import { inner, outer, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import Footer from '../components/Footer';
import "react-image-gallery/styles/scss/image-gallery.scss";

import { SiteHelmet } from "../components/Helmet";

import FixedBar from "../components/FixedBar";
import { MailSection } from "../components/MailSection";
import { graphql, StaticQuery } from "gatsby";
import { colors } from "../styles/colors";

const WrapperCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PageTemplate = css`
  .site-main {
    width: 100%;
  }
`;

const MainCSS = css`
  position: absolute;
  inset: 56px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

const FormCSS = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  padding: 16px 24px;
  @media (max-height: 400px) {
    padding-bottom: 16px;
    height: auto;
  }
`;

const RewardImageCSS = css`
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center;
  //box-shadow: inset 0px 0px 8px 0px rgba(0,0,0,1);
  border-bottom: solid 2px ${colors.logoOrange};
  
  @media (max-height: 400px) {
    height: 20vh;
  }

  @media (max-width: 600px) {
    height: 40vh;
  }
`;


type Props = {
  data: any
}

const RewardImage: React.FC<{ url: string }> = ({ url }) => {
  return <div draggable={false} css={[RewardImageCSS]} style={{ backgroundImage: `url(${url})` }} />
}

// Landing
const Landing: React.FC<Props> = () => {
  return <IndexLayout>
    <SiteHelmet />
    <Wrapper css={[PageTemplate, WrapperCss]}>
      <FixedBar />
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={MainCSS}>
          <StaticQuery
            query={graphql`
                query {
                  reward: file(relativePath: { eq: "img/Wolf_outfit_3_players.png" }) {
                    publicURL
                  }
                }
            `}
            render={data => <RewardImage url={data.reward.publicURL} />} />
          <div css={FormCSS}>
            <MailSection />
          </div>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
}

export default Landing;
